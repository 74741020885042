<template lang="pug">
    v-dialog(v-model="showIncorrectWritingInput", persistent max-width="800px" overlay-color="primary" overlay-opacity="0.95")
        v-card.pa-5(flat color="accent")
            v-row
              v-col(cols="12", md="4")
                .display-1.mb-2.font-weight-bold.white--text Paste in your student's writing
                .text-h5.white--text (including their errors)
                v-img(v-if="$vuetify.breakpoint.mdAndUp", src="@/assets/right-drawn-arrow.svg" width="200px").mt-5
              v-col(cols="12", md="8")
                v-textarea(v-model="value", hide-details, background-color="yellow lighten-4" :rows="$vuetify.breakpoint.smAndDown ? 5 : 8" outlined label="Paste your student's writing here" placeholder="Copy and paste your student's original writing into this box. Then press 'Start Editing'", @keypress="handleKeyPress")
                
                v-alert(v-if="error", type="error", color="accent darken-2" dark).my-3 {{error}}
                v-btn(x-large color="primary", block, @click="handleSubmit").font-weight-bold.my-3 Start Editing
                  v-icon.ml-2 mdi-pen

            
            
</template>

<script>
import { sync } from "vuex-pathify";
import LinkCopy from "@/components/LinkCopy.vue";
export default {
  name: "DialogIncorrectWritingInput",
  components: { LinkCopy },
  props: {},
  data() {
    return { value: null, error: null };
  },
  computed: {
    ...sync({
      showIncorrectWritingInput: "Nav/dialogs@showIncorrectWritingInput",
    }),
  },
  methods: {
    handleSubmit() {
      this.error = null;
      if (this.value && this.value.trim() && this.value.trim().length > 0) {
        this.$emit("done", this.value);
      } else {
        this.error =
          "Start by pasting the text your student wrote into box above.";
      }
    },
    handleKeyPress(evt) {
      // Note this could be a bit of overkill but I found some
      // problems in Firefox and decided to go the distance
      // including old windows keyboard short cut keys.
      // Enumerate all supported clipboard, undo and redo keys
      var clipboardKeys = {
        winInsert: 45,
        winDelete: 46,
        SelectAll: 97,
        macCopy: 99,
        macPaste: 118,
        macCut: 120,
        redo: 121,
        undo: 122,
      };
      // Simulate readonly but allow all clipboard, undo and redo action keys
      var charCode = evt.which;
      //alert(charCode);
      // Accept ctrl+v, ctrl+c, ctrl+z, ctrl+insert, shift+insert, shift+del and ctrl+a
      if (
        (evt.ctrlKey && charCode == clipboardKeys.redo) /* ctrl+y redo			*/ ||
        (evt.ctrlKey && charCode == clipboardKeys.undo) /* ctrl+z undo			*/ ||
        (evt.ctrlKey && charCode == clipboardKeys.macCut) /* ctrl+x mac cut		*/ ||
        (evt.ctrlKey &&
          charCode == clipboardKeys.macPaste) /* ctrl+v mac paste		*/ ||
        (evt.ctrlKey &&
          charCode == clipboardKeys.macCopy) /* ctrl+c mac copy		*/ ||
        (evt.shiftKey &&
          evt.keyCode ==
            clipboardKeys.winInsert) /* shift+ins windows paste	*/ ||
        (evt.shiftKey &&
          evt.keyCode == clipboardKeys.winDelete) /* shift+del windows cut	*/ ||
        (evt.ctrlKey &&
          evt.keyCode == clipboardKeys.winInsert) /* ctrl+ins windows copy	*/ ||
        (evt.ctrlKey &&
          charCode == clipboardKeys.SelectAll) /* ctrl+a select all		*/
      ) {
        return 0;
      }
      // Shun all remaining keys simulating readonly textbox
      var theEvent = evt || window.event;
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
      // eslint-disable-next-line no-empty-character-class
      var regex = /[]|\./;
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        theEvent.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
$placeholderColor: red;
::v-deep textarea::-webkit-input-placeholder {
  color: $placeholderColor;
}

::v-deep textarea:-moz-placeholder {
  /* Firefox 18- */
  color: $placeholderColor;
}

::v-deep textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: $placeholderColor;
}

::v-deep textarea:-ms-input-placeholder {
  color: $placeholderColor;
}

::v-deep textarea::placeholder {
  color: $placeholderColor;
}
</style>
