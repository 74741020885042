<template lang="pug">
    ArticleWrapper.corrections
      
      v-row(align="center")
        v-col(cols="12")
          h1 English Writing Corrections
        v-col(cols="12", md="8")
            h2.mb-6.text-left Review these corrections from your teacher
        v-col(cols="12", md="4").text-right
          v-btn(color="accent" depressed :to="`/tools/writing-correction/${$route.params.id}`") Edit again
            v-icon.ml-2 mdi-pen

      template(v-if="!originalLines || !newLines")
        v-skeleton-loader(type="article")
        v-skeleton-loader(type="article")
      
      template(v-else)
        DiffLines(v-if="originalLines && newLines" :originalLines="originalLines", :newLines="newLines")

        v-card.pa-5(flat color="grey lighten-4").mt-4
          v-row(align="center")
            v-col(cols="12" md="8")
              h2 How did we make this?
              p You're looking at a writing correction tool from SeriCity. 
              p We make lesson plans for English teachers, based on TV Show content.
              v-btn(color="primary" depressed x-large to="/").mt-3 Try it Now
            v-col(cols="12" md="4")
              router-link(to="/").text-decoration-none
                v-img(src="@/assets/logo.png" contain max-width="280px").mx-auto
                .text-center
                  b www.SeriCity.com
        
</template>

<script>
import { get, call } from "vuex-pathify";
import ArticleWrapper from "@/components/Articles/ArticleWrapper.vue";
import DiffLines from "@/components/Corrections/DiffLines.vue";
export default {
  name: "Corrections",
  components: { ArticleWrapper, DiffLines },
  data() {
    return {
      originalText: null,
      value: null,
      showShare: false,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({ correction: "Tools/correction" }),
    originalLines() {
      return this.correction && this.correction.original
        ? this.correction.original.split("\n")
        : [];
    },
    newLines() {
      return this.correction && this.correction.edited
        ? this.correction.edited.split("\n")
        : [];
    },
  },
  methods: {
    ...call({ updateCorrection: "Tools/updateCorrection" }),
    async init() {
      await this.updateCorrection({ id: this.$route.params.id });
    },
  },
};
</script>

<style lang="scss" scoped></style>
