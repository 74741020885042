<template lang="pug">
  .corrections-toolbar.d-flex.flex-column(:class="{'flex-column-reverse': bottom}")
    v-card.pa-3(color="grey lighten-3" flat)
            .d-flex.flex-column.flex-md-row.justify-center.justify-md-space-between
              .left.flex-grow-1
                v-btn(color="accent" :block="$vuetify.breakpoint.smAndDown" :x-large="$vuetify.breakpoint.smAndDown" depressed @click="$emit('showShare')").btn.font-weight-bold
                  |  Share Corrections
                  v-icon.ml-1 mdi-share
              .right.text-center
                v-btn(color="primary" text :block="$vuetify.breakpoint.smAndDown", @click="originalIsShowing = !originalIsShowing").btn.font-weight-bold.my-2.my-md-0.ml-md-2 {{originalIsShowing ? "Hide Original" : "See Original"}}
                v-btn(v-if="$vuetify.breakpoint.mdAndUp", color="primary" text @click="handleReset").btn Reset

    v-expand-transition
      v-card(v-if="originalIsShowing", flat tile color="secondary").pa-5
        h2.my-0.mb-3 Original Text (with errors)
        p.line(v-for="originalLine, idx in originalLines", :key="originalLine + idx") {{originalLine}}
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  name: "CorrectionsToolbar",
  props: {
    bottom: { type: Boolean, deafult: false },
    originalLines: { type: Array, default: () => [] },
  },
  data() {
    return {
      originalIsShowing: false,
    };
  },
  computed: {
    ...sync({ shareCorrections: "Nav/dialogs@shareCorrections" }),
  },
  methods: {
    handleReset() {
      location.href = "/tools/writing-correction";
    },
  },
};
</script>
