<template lang="pug">
    ArticleWrapper.corrections
      DialogIncorrectWritingInput(v-if="!$route.params.id", @done="handleIncorrectTextInput")
      DialogShare(:shareLink="shortLink")
      
      h1 English Writing Correction
      .my-6.text-left.text-md-center.font-weight-bold Instructions: correct the incorrect text, then press the "Share Corrections" button to send it to your student.

      v-row(:dense="$vuetify.breakpoint.smAndDown")
        v-col(cols="12")
          CorrectionsToolbar(v-if="$vuetify.breakpoint.mdAndUp", :originalLines="originalLines", @showShare="handleShowShare")

        v-col(cols="12" md="6")
          v-textarea(v-model="value", :rows="rowsLength" outlined placeholder="Paste the original text (with errors) here and start correcting it.", @input="handleChange" @paste="handlePaste")
        
        v-col(cols="12" md="6")
            v-card(v-if="step < 3", outlined :color="step === 1 ? 'grey lighten-3' : 'secondary'" border="left").pa-5.mb-4
              .d-flex.justify-space-between.mb-3
                .title.font-weight-bold Corrections
                b.step Step {{step}}
              
              p.mb-0(v-if="step === 1") Start by pasting some text into the box. Then make your corrections to it. The differences will be shown here.
              p.mb-0(v-else-if="step === 2") Correct this text, and your edits will appear below.
            
            DiffLines(:originalLines="originalLines", :newLines="newLines")

        
        v-col(cols="12" v-if="$vuetify.breakpoint.smAndDown || rowsLength > 20")
          CorrectionsToolbar(bottom :originalLines="originalLines", @showShare="handleShowShare")

</template>

<script>
import { get, sync, call } from "vuex-pathify";
import ArticleWrapper from "@/components/Articles/ArticleWrapper.vue";
import CorrectionsToolbar from "@/components/Corrections/CorrectionsToolbar.vue";
import DialogShare from "@/components/Corrections/DialogShare.vue";
import DialogIncorrectWritingInput from "@/components/Corrections/DialogIncorrectWritingInput.vue";
import DiffLines from "@/components/Corrections/DiffLines.vue";
export default {
  name: "Corrections",
  components: {
    ArticleWrapper,
    CorrectionsToolbar,
    DialogShare,
    DialogIncorrectWritingInput,
    DiffLines,
  },
  data() {
    return {
      originalText: null,
      value: null,
      showShare: false,
      shortLink: null,
    };
  },
  created() {
    this.showIncorrectWritingInput = true;

    if (this.$route.params.id) this.init();
  },
  computed: {
    ...get({ hostname: "hostname", correction: "Tools/correction" }),
    ...sync({
      shareCorrections: "Nav/dialogs@shareCorrections",
      showIncorrectWritingInput: "Nav/dialogs@showIncorrectWritingInput",
    }),
    rowsLength() {
      if (this.$vuetify.breakpoint.smAndDown) return 5;

      if (!this.originalText) return 5;

      const numSentences = this.originalText.split(".").length;
      return numSentences + 10;
    },
    originalLines() {
      return this.originalText ? this.originalText.split("\n") : [];
    },
    newLines() {
      return this.value ? this.value.split("\n") : [];
    },
    step() {
      if (!this.originalText || this.originalText.trim().length < 1) return 1;
      else if (this.originalText.trim() === this.value.trim()) return 2;
      else return 3;
    },
  },
  methods: {
    ...call({
      getDynamicLink: "Nav/getDynamicLink",
      createCorrectionForReview: "Tools/createCorrectionForReview",
      updateCorrection: "Tools/updateCorrection",
    }),
    async init() {
      await this.updateCorrection({ id: this.$route.params.id });
      window.debug("setting original", this.correction.original);
      this.originalText = "" + this.correction.original;
      this.value = "" + this.correction.edited;
    },
    handleIncorrectTextInput(e) {
      this.originalText = e;
      this.value = e;
      this.showIncorrectWritingInput = false;
    },
    handleChange(e) {
      if (!e || e.trim().length < 1) {
        this.originalText = null;
      } else if (!this.originalText) {
        this.originalText = e;
      }
    },
    handlePaste() {
      if (this.originalText && this.originalText !== this.value) {
        // must be a reset
        window.debug("resetting");
        this.originalText = null;
      }
    },
    async handleShowShare() {
      const correctionId = await this.createCorrectionForReview({
        original: this.originalText,
        edited: this.value,
      });
      window.debug("correctionId", correctionId);

      this.shortLink = this.hostname + "/tools/corrections/" + correctionId;
      this.shareCorrections = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
