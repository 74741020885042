<template lang="pug">
    .hangman-maker
        ArticleWrapper(:style="{ 'margin-bottom': '200px'}")
            h1.mb-4 Make a Word Guessing Game
            
            v-row.my-5
                v-col(cols="12" md="6").grey.lighten-4
                    p
                        b Instructions:
                    ol.ml-4
                        li Type your target words...
                        li Followed by three hyphens: "---"
                        li Followed by the meaning/definition of those words
                        li (optional) Followed by three hyphens and an example sentence.
                        li One entry (i.e. word---meaning---example) per line, please.
                v-col(cols="12" md="6").yellow.lighten-5
                    p 
                        b Example:
                    .fixed-width-font.font-weight-bold.pa-3
                        p.ml-4  take a holiday --- to take a trip somewhere or relax from work --- This winter our family is planning to take a holiday to Egypt.
                        p.ml-4  do a favor --- to do something nice to help someone
            
            v-card.pa-5(color="grey lighten-4" tile flat).mb-4
                v-text-field(:readonly="showShare", label="Game Title" placeholder="Type a title for your game", v-model="title", outlined, :background-color="showShare ? 'grey lighten-2' : null", clearable)
                v-textarea(:readonly="showShare", label="Words and Meanings", v-model="value", placeholder="dog --- a household pet with four legs --- My dog is my best friend\nspider --- an insect with 6 legs --- I found a big black spider spinning a web in my garden." outlined rows="6", clearable, :background-color="showShare ? 'grey lighten-2' : null")
                .buttons
                    template(v-if="showShare")
                        v-btn(color="primary" outlined @click="showShare = false")
                            v-icon.mr-1 mdi-undo
                            | Edit words again
                    template(v-else)
                        v-btn(color="primary", :disabled="showShare", depressed @click="handleCreate").mr-md-2
                            v-icon.mr-2 mdi-check
                            | SAVE + Get Link
                        v-btn(@click="value = placeholder", text color="primary") Try an example
                    
                v-card.shareable(v-if="showShare" flat color="grey lighten-5").pa-3.mt-3
                    LinkCopy(:link="worksheetLink" @done="copied=true")
            
            .preview
                Hangman(:loading="debounce", title="Preview of your Guessing Game", subtitle="Words will appear in random order every time", :keyExpressions="items", @change="currentTerm = $event", @reset="")
</template>

<script>
import ArticleWrapper from "@/components/Articles/ArticleWrapper.vue";
import Hangman from "@/components/Games/Hangman/Hangman.vue";
import LinkCopy from "@/components/LinkCopy.vue";
import { get, call } from "vuex-pathify";
export default {
  name: "HangmanMaker",
  components: { ArticleWrapper, Hangman, LinkCopy },
  watch: {
    value() {
      clearTimeout(this.debounceTimer);
      this.debounce = true;

      this.debounceTimer = setTimeout(() => {
        this.debounce = false;
      }, 2000);
    },
  },
  data() {
    return {
      title: "Halloween Game",
      value: null,
      currentTerm: null,
      worksheetId: null,
      showShare: false,
      debounce: false,
      debounceTimer: null,
    };
  },
  created() {
    this.value = this.placeholder;
  },
  computed: {
    ...get({ hostname: "hostname" }),
    placeholder() {
      return this.example
        .map(
          (itm) =>
            itm.expression +
            " --- " +
            itm.meaning +
            (itm.example ? " --- " + itm.example : "")
        )
        .join("\n\n");
    },
    worksheetLink() {
      return this.worksheetId
        ? this.hostname + "/games/hangman/" + this.worksheetId
        : null;
    },
    example() {
      return [
        {
          expression: "pumpkin",
          meaning: "a large orange-yellow fruit that is popular on Halloween",
          example:
            "My dad loves carving scary faces into pumpkins on Halloween",
        },
        {
          expression: "to dress up",
          meaning: "to put on special or fancy clothing, for a special event",
        },
        {
          expression: "trick or treat",
          meaning: "something children say on Halloween to get candy",
        },
      ];
    },
    items() {
      if (this.debounce) return [];

      const delimiter = "---";
      if (!this.value || !this.value.includes(delimiter)) return [];

      const items = this.value.split("\n");
      if (!items || items.length < 1) return [];
      const parsed = items
        .filter(Boolean)
        .map((itm) => {
          if (itm && itm.includes(delimiter)) {
            const split = itm.split(delimiter);
            if (!split || split.length < 2) return null;
            return {
              expression: split[0].trim(),
              meaning: split[1].trim(),
              example: split.length > 2 ? split[2].trim() : null,
            };
          } else {
            return null;
          }
        })
        .filter(Boolean);
      return parsed;
    },
  },
  methods: {
    ...call({ createWorksheet: "Tools/createWorksheet" }),
    async handleCreate() {
      if (this.items && this.items.length > 0) {
        const worksheetId = await this.createWorksheet({
          title: this.title,
          expressions: this.items,
          worksheetId: this.worksheetId,
        });
        this.worksheetId = worksheetId;
        this.showShare = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fixed-width-font p {
  font-family: monospace !important;
}
</style>
