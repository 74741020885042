<template lang="pug">
    v-dialog(v-model="shareCorrections" max-width="600px" overlay-color="primary" overlay-opacity="0.8")
        v-card.pa-5(flat)
            h1.text-center.mb-5 Share your corrections
            template(v-if="done")
              h2 Nice! We've copied that link. Now email or text it to your student(s)
              .my-6.text-center
              
                a(:href="shareLink" target="_blank").yellow.lighten-4.text--truncate.pa-2 {{shareLink}}
                v-btn(color="primary" depressed x-large @click="shareCorrections = false").mx-auto.mt-5
                  v-icon.mr-2 mdi-close
                  | Finished
            template(v-else)
              p Send your students the following link, and they can review the corrections you made.
              LinkCopy(:link="shareLink" @done="done = true")
            
            
</template>

<script>
import { sync } from "vuex-pathify";
import LinkCopy from "@/components/LinkCopy.vue";
export default {
  name: "Share",
  components: { LinkCopy },
  props: {
    shareLink: { type: String, default: null },
  },
  data() {
    return { done: false };
  },
  computed: {
    ...sync({ shareCorrections: "Nav/dialogs@shareCorrections" }),
  },
};
</script>
